export const styles = theme => ({
  container: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#F2F4FD",
    overflow: "auto",
    flexDirection: "row",
    display: "flex"
  },
  arrowBackWrapper: {
    width: 100,
    heigth: "100%",
    padding: 30
  },
  gobackButton: {
    width: 38,
    height: 38,
    backgroundColor: "#fff"
  },
  mainNewCaseWrapper: {
    width: "100%",
    height: "100%",
    overflow: "auto",
    borderRadius: 0,
    backgroundColor: "#fff",
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(6),
    paddingLeft: theme.spacing(6),
    paddingBottom: theme.spacing(4)
  },
  headText: {
    fontSize: 30,
    color: "#1D1D1D",
    marginLeft: theme.spacing(4)
  },
  contentPaper: {
    height: "90%",
    marginTop: 15,
    overflow: "auto"
  },
  dealTypeContainer: {
    width: "50%",
    marginLeft: "25%",
    marginTop: "3%",
    height: "10%"
  },
  dealTitleContainer: {
    marginTop: "3%",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    height: 50
  },
  dealTitle: {
    width: "100%"
  },
  descriptionContainer: {
    marginTop: "3%",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    height: "45%"
  },
  description: {
    width: "100%"
  },
  datePicker: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1)
  },
  createNewDealsButton: {
    width: 211,
    height: 44,
    background: '#4D87F8',
    textTransform: "none"
  },
  editDealButton: {
    height: 25,
    borderRadius: 30,
    paddingTop: 0,
    textTransform: "capitalize",
    marginTop: 10
  },
  imgContain: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "70%",
    paddingTop: 15
  },
  imgItem: {
    height: "100%",
    width: "65%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    margin: 15
  }
});