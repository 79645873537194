export function convertTimeStampToDateAndTime(timestamp) {
  let day = new Date(Number(timestamp*1000));
  let date = day.getFullYear()+'-'+('0'+ (day.getMonth()+1)).slice(-2)+'-'+('0' + day.getDate()).slice(-2);

  return `${date}`
}

export function getPeriodFromTo(startDate, endDate) {
  const startDay = new Date(Number(startDate * 1000));
  const endDay = new Date(Number(endDate * 1000));
  const monthArr = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
  const start = `${monthArr[startDay.getMonth()]} ${('0' + startDay.getDate()).slice(-2)}`;
  const end = `${monthArr[endDay.getMonth()]} ${('0' + endDay.getDate()).slice(-2)}`;
  return `${start} - ${end}`
}

export const urlToBase64 = async url => {
  const _url = "https://cors-anywhere.herokuapp.com/" + url
  const image2base64 = require("image-to-base64");
  return await image2base64(_url)
    .then(res => {
      return res;
    })
    .catch(error => {
      console.error(error);
    });
};

export const b64toBlob = (b64Data, contentType, sliceSize) => {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

export const fileToBase64 = files =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(files[0]);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });