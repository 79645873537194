export const styles = theme => ({
  cardContain: {
    width: '100%',
    height: `calc(100% - (90px))`,
    fallbacks: [
      { height: `-moz-calc(100% - (90px))` },
      { height: `-webkit-calc(100% - (90px))` },
      { height: `-o-calc(100% - (90px))` },
    ],
    padding: "5%"
  },
  dealTitleContainer: {
    marginTop: "5%",
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    height: 50
  },
  dealTitle: {
    width: "100%"
  },
  descriptionContainer: {
    marginTop: 20,
    padding: theme.spacing(1.5),
    paddingLeft: theme.spacing(3),
    height: `calc(100% - (250px))`,
    fallbacks: [
      { height: `-moz-calc(100% - (250px))` },
      { height: `-webkit-calc(100% - (250px))` },
      { height: `-o-calc(100% - (250px))` },
    ]
  },
  description: {
    width: "100%",
    height: "100%",
    alignItems: "flex-start"
  },
  detailInfo: {
    fontWeight: "300",
    fontSize: 12,
    fontFamily: "Nunito Sans",
    color: "#0D0D0D"
  },
  itemInput: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(3)
  },
  input: {
    width: "100%"
  },
  editDealButton: {
    height: 39,
    borderRadius: 30,
    textTransform: "capitalize"
  },
})