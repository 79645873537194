import React from 'react';
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import { uploadFile } from 'react-s3';
import {
  Grid,
  Fab,
  Card,
  Paper,
  TextField,
  MenuItem,
  InputBase,
  Divider,
  Button,
  Typography
} from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../Session';
import DropzonePlugin from './DropzonePlugin';
import { S3Config, dealTypeList } from '../../constants/constants';
import * as ROUTES from '../../constants/routes';
import { getPeriodFromTo, fileToBase64, urlToBase64 } from '../../constants/functions';
import { styles } from '../../assets/styles/editdealPageStyle';

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

class EditdealPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      dealType: '',
      dealTitle: '',
      siteLink: '',
      dealDescription: '',
      files: [],
      startDate: new Date(),
      endDate: new Date(),
      filePaths: [],
      isCreate: true,
      editDealID: null,
      imagePreviewBase64: '',
      prevDeals: []
    }
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this.props.location.state && this.props.location.state.detail) {
      const deal = this.props.location.state.detail;
      this._isMounted && this.setState({
        isCreate: false,
        editDealID: deal.id,
        dealType: deal.dealType,
        dealTitle: deal.dealTitle,
        siteLink: deal.siteLink,
        dealDescription: deal.dealDescription,
        files: [],
        startDate: new Date(deal.startDate.seconds * 1000),
        endDate: new Date(deal.endDate.seconds * 1000),
        imagePreviewBase64: `data:image/png;base64, ${await urlToBase64(deal.filePaths[0])}`,
        filePaths: deal.filePaths
      })
    }
    this._isMounted && this.getPrevDeals();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    if (nextProps.firebase.auth.currentUser) {
      this._isMounted && this.getPrevDeals()
    }
  }

  getPrevDeals = async () => {
    let prevDeals = [];
    prevDeals = await this.props.firebase.getDeals();
    this.setState({ prevDeals });
  }

  goBack = () => {
    this.props.history.push(`page:${ROUTES.ADS}`);
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleFileUpload = (files) => {
    this.setState({ files });
    fileToBase64(files).then((imagePreviewBase64) => {
      this.setState({ imagePreviewBase64 })
    })
  }

  handleStartDateChange = date => {
    this.setState({
      startDate: date
    });
  };

  handleEndDateChange = date => {
    this.setState({
      endDate: date
    });
  };

  handleSubmit = async () => {
    const {
      dealType,
      dealTitle,
      siteLink,
      dealDescription,
      files,
      startDate,
      endDate,
      filePaths,
      isCreate,
      editDealID,
    } = this.state;
    // Upload to aws s3 bucket
    const current_time = new Date();
    const current_timestamp = current_time.getTime();

    let promise = await Promise.all(files.map(async file => {
      if (file) {
        const new_file = new File([file], `${file.name}:${current_timestamp}`, { type: file.type });
        return await uploadFile(new_file, S3Config)
      } else return
    }))

    promise
      .filter(item => item && item.location)
      .map(item => filePaths.push(item.location))

    const authID = this.props.firebase.auth.currentUser.uid;
    const documentID = isCreate ? current_timestamp : editDealID;
    this.props.firebase.deal(documentID).set({
      id: isCreate ? current_timestamp : editDealID,
      createdBy: authID,
      dealType,
      dealTitle,
      siteLink,
      dealDescription,
      filePaths,
      startDate,
      endDate,
      isAlive: true
    })
  }

  handleReUseDeal = async (e, deal) => {
    e.preventDefault();
    this.setState({
      isCreate: true,
      dealType: deal.dealType,
      dealTitle: deal.dealTitle,
      siteLink: deal.siteLink,
      dealDescription: deal.dealDescription,
      imagePreviewBase64: `data:image/png;base64, ${await urlToBase64(deal.filePaths[0])}`,
      filePaths: deal.filePaths
    })
  }

  render() {

    const { classes } = this.props;
    const { functionName } = this.props.match.params;
    const {
      dealType,
      dealTitle,
      siteLink,
      dealDescription,
      files,
      startDate,
      endDate,
      imagePreviewBase64,
      prevDeals
    } = this.state;
    const defaultImgBase64String = "data:image/png;base64, SW52YWxpZCBob3N0OiB1bmRlZmluZWQ=";

    return (
      <div className={classes.container}>
        <div className={classes.arrowBackWrapper}>
          <Fab
            color="inherit"
            aria-label="add"
            className={classes.gobackButton}
            onClick={this.goBack}
          >
            <ArrowBackIcon color="action" />
          </Fab>
        </div>
        <Grid container justify="flex-start" style={{overflow: "hidden"}}>
          <Grid item xs={12} md={8} style={{ height: "100%" }}>
            <Card className={classes.mainNewCaseWrapper}>
              <Typography className={classes.headText}>
                {functionName === 'create' ? 'Create Ad' : 'Edit Ad'}
              </Typography>
              <Card className={classes.contentPaper}>
                <div className={classes.dealTypeContainer}>
                  <Paper>
                    <TextField
                      select
                      variant="outlined"
                      required
                      fullWidth
                      id="deal-type"
                      label="Select Ad Type"
                      name="dealType"
                      autoComplete="off"
                      value={dealType}
                      onChange={this.handleInputChange}
                      className={classes.specialItem}
                    >
                      {dealTypeList.map(dealType => (
                        <MenuItem key={dealType.value} value={dealType.value}>{dealType.label}</MenuItem>
                      ))}
                    </TextField>
                  </Paper>
                </div>
                <Grid container style={{ padding: 20, height: "73%", paddingTop: 0, minHeight: 450 }}>
                  <Grid item xs={12} md={6}>
                    <Paper className={classes.dealTitleContainer}>
                      <InputBase
                        className={classes.dealTitle}
                        placeholder='Enter Ad Title Here'
                        autoComplete={'off'}
                        inputProps={{ 'aria-label': `deal-title` }}
                        name={'dealTitle'}
                        value={dealTitle}
                        onChange={this.handleInputChange}
                      />
                    </Paper>
                    <Paper className={classes.dealTitleContainer}>
                      <InputBase
                        className={classes.dealTitle}
                        placeholder='Enter Website URL Here'
                        autoComplete={'off'}
                        inputProps={{ 'aria-label': `deal-title` }}
                        name={'siteLink'}
                        value={siteLink}
                        onChange={this.handleInputChange}
                      />
                    </Paper>
                    <Paper className={classes.descriptionContainer}>
                      <InputBase
                        className={classes.description}
                        placeholder='Enter a description of what you’re offering '
                        inputProps={{ 'aria-label': `deal-description` }}
                        autoComplete={'off'}
                        multiline={true}
                        name={'dealDescription'}
                        value={dealDescription}
                        onChange={this.handleInputChange}
                      />
                    </Paper>
                    <div style={{ display: "flex", marginTop: "5%" }}>
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="Start Date"
                        format="MM/dd/yyyy"
                        value={startDate}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={date => this.handleStartDateChange(date)}
                        className={classes.datePicker}
                      />
                      <KeyboardDatePicker
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        label="End Date"
                        format="MM/dd/yyyy"
                        value={endDate}
                        InputAdornmentProps={{ position: "end" }}
                        onChange={date => this.handleEndDateChange(date)}
                        className={classes.datePicker}
                      />
                    </div>
                  </Grid>
                  <Grid item xs={12} md={6} style={{ padding: 15, paddingTop: 35 }}>
                    <Typography style={{
                      fontFamily: "Nunito Sans",
                      fontSize: 22,
                      color: "#2B2B2B",
                      textAlign: "center"
                    }}>
                      Upload Image.
                    </Typography>
                    <Typography style={{
                      fontFamily: "Nunito Sans",
                      fontSize: 14,
                      color: "#3F3F3F",
                      textAlign: "center"
                    }}>
                      Upload an image for your ad.
                    </Typography>
                    <div className={classes.imgContain}>
                      {imagePreviewBase64 && imagePreviewBase64 !== defaultImgBase64String ? (
                        <div className={classes.imgItem}>
                          <img src={imagePreviewBase64} style={{ width: "100%" }} alt="firstImage" />
                        </div>
                      ) : (
                          <DropzonePlugin
                            handleFileUpload={this.handleFileUpload}
                            files={files}
                          />
                      )}
                    </div>
                  </Grid>
                </Grid>
                <Divider />
                <div style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "10%"
                }}>
                  <Button
                    variant="contained"
                    component={AdapterLink}
                    to={`/page:${ROUTES.ADS}`}
                    color="primary"
                    className={classes.createNewDealsButton}
                    onClick={this.handleSubmit}>
                    {functionName === 'create' ? 'Create new Ad' : 'Update Ad'}
                  </Button>
                </div>
              </Card>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4} style={{ padding: 30, overflow: "auto", height: "100%" }}>
            <Typography style={{
              fontFamily: "Nunito Sans",
              fontWeight: "600",
              fontSize: 24,
              color: "#454545"
            }}>
              Previous Ads
            </Typography>
            {prevDeals && prevDeals.length >= 1 && prevDeals.map((deal, key) => (
              <Card key={key} style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: 20,
                marginTop: 20
              }}>
                <Typography style={{
                  fontFamily: "Nunito Sans",
                  fontWeight: "600",
                  fontSize: 12,
                  color: "#525252"
                }}>
                  {getPeriodFromTo(deal.startDate.seconds, deal.endDate.seconds)}
                </Typography>
                <Typography style={{
                  fontFamily: "Nunito Sans",
                  fontWeight: "600",
                  fontSize: 22,
                  color: "#2B2B2B",
                  marginTop: 10
                }}>
                  {deal.dealTitle}
                </Typography>
                <Button
                  variant="contained"
                  color={"primary"}
                  style={{ backgroundColor: "#4c5df8" }}
                  className={classes.editDealButton}
                  onClick={(e) => this.handleReUseDeal(e, deal)}>
                  Re-use Ad
                </Button>
              </Card>
            ))}
          </Grid>
        </Grid>
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  withStyles(styles)
)(EditdealPage);