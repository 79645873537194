export const styles = theme => ({
  card: {
    width: '100%',
    height: `calc(100% - (90px))`,
    fallbacks: [
      { height: `-moz-calc(100% - (90px))` },
      { height: `-webkit-calc(100% - (90px))` },
      { height: `-o-calc(100% - (90px))` },
    ]
  },
  headerContainer: {
    margin: '28px 21px 7px 21px',
    width: `calc(100% - (42px))`,
    fallbacks: [
      { width: `-moz-calc(100% - (42px))` },
      { width: `-webkit-calc(100% - (42px))` },
      { width: '-o-calc(100% - 42px)' }
    ]
  },
  searchBar: {
    margin: theme.spacing(1),
    flexBasis: 200,
  },
  tableHeaderColumn: {
    backgroundColor: "#F4F4F4",
    borderTop: '1px solid #C2C2C2'
  },
  headText: {
    fontFamily: "Nunito Sans",
    fontWeight: "500",
    fontSize: 16,
    color: '#1F1F1F',
  },
  bodyText: {
    fontFamily: "Nunito Sans",
    fontWeight: "400",
    fontSize: 16,
    color: '#515151',
  },
  dealStatusButton: {
    width: 112,
    height: 25,
    borderRadius: 5,
    paddingTop: 0,
    textTransform: "capitalize"
  },
  statusButtonLabel: {
    color: "white"
  },
  editDealButton: {
    height: 25,
    borderRadius: 30,
    paddingTop: 0,
    textTransform: "capitalize"
  },
  datePicker: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(2)
  }
})