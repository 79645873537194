export const dealTypeList = [
  { value: 'Food & Drink', label: 'Food & Drink'},
  { value: 'Shopping', label: 'Shopping'},
  { value: 'Services', label: 'Services'},
  { value: 'Premium Tile', label: 'Premium Tile'}
];

export const S3Config = {
  bucketName: 'greenbelt-web-app',
  dirName: 'files-for-deal', /* optional */
  region: 'us-east-2',
  accessKeyId: 'AKIAJVAAA7JHJWBHQ47A',
  secretAccessKey: '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',
}

export const businessInfoItems = [
  {
    name: 'Business Name',
    value: 'businessName',
    type: 'text'
  },
  {
    name: 'Business Address',
    value: 'businessAddress',
    type: 'text'
  },
  {
    name: 'Phone Number',
    value: 'phoneNumber',
    type: 'tel'
  }
];

export const changePasswordItems = [
  {
    name: 'Current Password',
    value: 'currentPassword',
    type: 'password'
  },
  {
    name: 'New Password',
    value: 'newPassword',
    type: 'password'
  },
  {
    name: 'Re-Type New Password',
    value: 'confirmPassword',
    type: 'password'
  }
];

export const contactInfoItems = [
  {
    name: 'Your Name',
    value: 'yourName',
    type: 'text'
  },
  {
    name: 'Your Email Address',
    value: 'yourEmailAddress',
    type: 'text'
  },
  {
    name: 'Your Phone Number (Optional)',
    value: 'yourPhoneNumber',
    type: 'tel'
  }
];