export const styles = theme => ({
  card: {
    width: '100%',
    height: `calc(100% - (90px))`,
    fallbacks: [
      { height: `-moz-calc(100% - (90px))` },
      { height: `-webkit-calc(100% - (90px))` },
      { height: `-o-calc(100% - (90px))` },
    ],
    padding: theme.spacing(3.5),
    marginBottom: 40
  },
  infoTitle: {
    fontWeight: "600",
    fontSize: 16,
    fontFamily: "Nunito Sans",
    color: "#0D0D0D"
  },
  detailInfo: {
    fontWeight: "300",
    fontSize: 12,
    fontFamily: "Nunito Sans",
    color: "#0D0D0D"
  },
  itemInput: {
    marginTop: theme.spacing(0.5),
    padding: theme.spacing(0.5),
    paddingLeft: theme.spacing(3)
  },
  input: {
    width: "100%"
  },
  changePwd: {
    fontWeight: "600",
    fontSize: 12,
    fontFamily: "Nunito Sans",
    color: "#0D0D0D",
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing(3)
  },
  editDealButton: {
    height: 39,
    borderRadius: 30,
    textTransform: "capitalize"
  },
  errorText: {
    fontSize: 12,
    fontWeight: "500",
    marginTop: theme.spacing(1)
  }
})