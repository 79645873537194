import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";

import SignUpPage from './components/SignUp';
import SignInPage from './components/SignIn';
import ConfirmEmailPage from './components/ConfirmEmail';
import PasswordForgetForm from './components/PasswordForget';
import PasswordChangeForm from './components/PasswordChange';
import HomePage from './components/Home';
import EditdealPage from './components/Editdeal';

import * as ROUTES from './constants/routes';
import { withAuthentication } from './components/Session';

const THEME = createMuiTheme({
  typography: {
    //  "fontFamily": "\"Open Sans\", Semibold",
    "fontFamily": "\"Open Sans\", Nunito Sans",
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500,
    "fontWeightBold": 700
  }
});

const App = () => (
  <MuiThemeProvider theme={THEME}>
    <CssBaseline />
    <Router>
      <div>
        <Route exact path={ROUTES.LANDING} component={SignInPage} />
        <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
        <Route path={ROUTES.SIGN_IN} component={SignInPage} />
        <Route path={ROUTES.CONFIRM_EMAIL} component={ConfirmEmailPage} />
        <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForgetForm} />
        <Route path={ROUTES.PASSWORD_CHANGE} component={PasswordChangeForm} />
        <Route path={`/page:componentName`} component={HomePage} />
        <Route path={`/ads-:functionName`} component={EditdealPage} />
      </div>
    </Router>
  </MuiThemeProvider>
);

export default withAuthentication(App);