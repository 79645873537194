import React, { Component } from 'react';
import { compose } from 'recompose';
import {
  Card,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  InputAdornment,
  IconButton,
  Grid,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide
} from "@material-ui/core";
import SearchIcon from '@material-ui/icons/Search';
import { KeyboardDatePicker } from "@material-ui/pickers";
import { withStyles } from "@material-ui/core/styles";
import moment from "moment";
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import { convertTimeStampToDateAndTime } from '../../constants/functions';
import _ from "../../@lodash";
import { withFirebase } from '../Firebase';
import { styles } from '../../assets/styles/dealsPageStyle';

const thList = ['Ad Type', 'Ad Name', 'Ad Status', 'End Date', '', ''];

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class Ads extends Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: new Date(),
      endDate: new Date(),
      deals: [],
      searchResults: [],
      openDialog: false,
      togglingDeal: ''
    };
    this._isMounted = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidMount() {
    this._isMounted = true;
    if (this.props.firebase.auth.currentUser) {
      this._isMounted && this.getDeals()
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    if (nextProps.firebase.auth.currentUser) {
      this._isMounted && this.getDeals()
    }
  }

  getDeals = async () => {
    let { deals } = this.state;
    deals = await this.props.firebase.getDeals();    
    this._isMounted && this.setState({ deals, searchResults: deals });
    const __this = this;
    const authID = this.props.firebase.auth.currentUser.uid;
    this.props.firebase.deals().orderBy('isAlive', 'desc')
      .onSnapshot(function (querySnapshot) {
        let changedDeals = []
        querySnapshot.forEach(function (doc) {
          doc.data().createdBy === authID &&
            changedDeals.push(doc.data());
        });
        __this._isMounted && __this.setState({ 
          deals: changedDeals, 
          searchResults: changedDeals 
        });
      });
  }

  handleChange = e => {
    e.preventDefault();
    let searchText = e.target.value;
    const { deals } = this.state;
    if (searchText !== '') {
      let searchResults = _.filter(deals, function (item) {
        return item.dealTitle.indexOf(searchText) > -1 || item.dealType.indexOf(searchText) > -1;
      });
      this.setState({ searchResults });
    } else {
      this.setState({ searchResults: deals });
    }
  }

  handleClickSearch = () => {

  }

  handleStartDateChange = date => {
    const searchDate = moment(date).format(`YYYY-MM-DD`);
    this.setState({
      startDate: date
    });
    const { deals } = this.state;
    let searchResults = [];
    deals
      .filter(deal => searchDate === convertTimeStampToDateAndTime(deal.startDate.seconds))
      .map(deal => searchResults.push(deal))
    this.setState({ searchResults });
  };

  handleEndDateChange = date => {
    const searchDate = moment(date).format(`YYYY-MM-DD`);
    this.setState({
      endDate: date
    });
    const { deals } = this.state;
    let searchResults = [];
    deals
      .filter(deal => searchDate === convertTimeStampToDateAndTime(deal.endDate.seconds))
      .map(deal => searchResults.push(deal))
    this.setState({ searchResults });
  };

  handleEditClick = (e, deal) => {
    e.preventDefault();
    this.props.history.push({
      pathname: `/ads-${ROUTES.EDITDEALS}`,
      state: { detail: deal }
    })
  }

  handleToggleActiveClick = (e, deal) => {
    e.preventDefault();
    this.setState({
      openDialog: true,
      togglingDeal: deal
    })
  }

  handleDisagree = () => {
    this.setState({ openDialog: false })
  }

  handleAgree = (e, isDelete) => {
    e.preventDefault();
    this.setState({ openDialog: false });
    isDelete
      ? this.deleteDeal(this.state.togglingDeal)
      : this.reActivateDeal(this.state.togglingDeal)
  }

  deleteDeal = (togglingDeal) => {
    this.props.firebase.deal(togglingDeal.id).update({
      isAlive: false
    })
  }

  reActivateDeal = (togglingDeal) => {
    this.props.firebase.deal(togglingDeal.id).update({
      isAlive: true
    })
  }

  render() {
    const { classes } = this.props;
    const {
      searchResults,
      openDialog,
      togglingDeal
    } = this.state;

    return (
      <div style={{ display: "inline" }}>
        <Card className={classes.card}>
          <Grid container spacing={2} className={classes.headerContainer}>
            <Grid item xs={4}>
              <TextField
                id="outlined-adornment-password"
                className={classes.searchBar}
                variant="outlined"
                type={'text'}
                label="Search"
                name={"searchText"}
                onChange={this.handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        edge="end"
                        aria-label="search icon"
                        onClick={this.handleClickSearch}
                      >
                        <SearchIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={8} style={{ display: "flex", justifyContent: "flex-end" }}>
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="Start Date"
                format="MM/dd/yyyy"
                value={this.state.startDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={date => this.handleStartDateChange(date)}
                className={classes.datePicker}
              />
              <KeyboardDatePicker
                autoOk
                variant="inline"
                inputVariant="outlined"
                label="End Date"
                format="MM/dd/yyyy"
                value={this.state.endDate}
                InputAdornmentProps={{ position: "end" }}
                onChange={date => this.handleEndDateChange(date)}
                className={classes.datePicker}
              />
            </Grid>
          </Grid>
          <Table>
            <TableHead>
              <TableRow className={classes.tableHeaderColumn}>
                {thList.map((th, index) => {
                  return (
                    <TableCell align="left" key={index}>
                      <Typography className={classes.headText}>{th}</Typography>
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {searchResults.map((deal, key) => (
                <TableRow className={classes.tableBodyRow} key={key}>
                  <TableCell align="left">
                    <Typography className={classes.bodyText}>{deal.dealType}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.bodyText}>{deal.dealTitle}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color={"secondary"}
                      disabled={true}
                      style={{ backgroundColor: deal.isAlive ? "#1ccd0e" : "#f86870" }}
                      classes={{
                        root: classes.dealStatusButton,
                        label: classes.statusButtonLabel
                      }}>
                      {deal.isAlive ? `Live Ad` : `Closed Ad`}
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    <Typography className={classes.bodyText}>{convertTimeStampToDateAndTime(deal.endDate.seconds)}</Typography>
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      variant="contained"
                      color={"primary"}
                      style={{ backgroundColor: "#4c5df8" }}
                      className={classes.editDealButton}
                      onClick={(e) => this.handleEditClick(e, deal)}>
                      Edit Ad
                    </Button>
                  </TableCell>
                  <TableCell align="left">
                    {deal.isAlive ? (
                      <Button
                        variant="contained"
                        color={"secondary"}
                        style={{ backgroundColor: "#f86870" }}
                        className={classes.editDealButton}
                        onClick={(e) => this.handleToggleActiveClick(e, deal)}>
                        Deactivate Ad
                      </Button>
                    ):(
                      <Button
                        variant="contained"
                        color={"primary"}
                        style={{ backgroundColor: "#4c5df8" }}
                        className={classes.editDealButton}
                        onClick={(e) => this.handleToggleActiveClick(e, deal)}>
                        Reactivate Ad
                      </Button>
                    )
                    }
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Card>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleDisagree}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            {togglingDeal.isAlive
              ? `Are you sure want to deactivate this ad?`
              : `Are you sure want to re-activate this ad?`
            }
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleDisagree} color="primary" variant="contained">
              Back
            </Button>
            <Button 
              onClick={(e) =>
                togglingDeal.isAlive 
                  ? this.handleAgree(e, true) 
                  : this.handleAgree(e, false)
              } 
              color="secondary" 
              variant="contained">
              {togglingDeal.isAlive
                ? `Deactivate`
                : `Reactivate`
              }
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withFirebase,
  withEmailVerification,
  withAuthorization(condition),
  withStyles(styles)
)(Ads);