import React, { Component } from 'react';
import Avatar from 'react-avatar-edit'
import { withAuthorization, withEmailVerification } from '../Session';
import { compose } from 'recompose';
import {
  Card,
  Grid,
  Typography,
  Paper,
  Button,
  InputBase,
  Dialog,
  DialogActions,
  DialogContent,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";
import LogoImage from "../../assets/images/logo.svg";
import LogoTitle from "../../assets/images/logoTitle.svg";
import { businessInfoItems, changePasswordItems } from "../../constants/constants";
import { b64toBlob, urlToBase64 } from "../../constants/functions";
import { styles } from "../../assets/styles/accountPageStyle";

class Account extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userID: '',
      businessName: '',
      businessAddress: '',
      phoneNumber: '',
      currentPassword: '',
      newPassword: '',
      confirmPassword: '',
      emailAddress: '',
      open: false,
      previewAvatarBase64: '',
      previewAvatarSrc: '',
      originalAvatarBase64: '',
      originalAvatarSrc: ''
    };
    this._isMounted = false;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  async componentDidMount() {
    this._isMounted = true;
    if (this.props.firebase.auth.currentUser) {
      const userID = this.props.firebase.auth.currentUser.uid;
      this.props.firebase.user(userID).get()
        .then(async user => {
          const currentUser = user.data();
          this._isMounted && this.setState({
            businessName: currentUser.businessName,
            businessAddress: currentUser.businessAddress,
            phoneNumber: currentUser.phoneNumber,
            emailAddress: currentUser.emailAddress,
            originalAvatarBase64: await urlToBase64(currentUser.originalAvatarSrc),
            originalAvatarSrc: currentUser.originalAvatarSrc,
            previewAvatarSrc: currentUser.previewAvatarSrc
          })
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this._isMounted = true;
    if (nextProps.firebase.auth.currentUser) {
      const userID = this.props.firebase.auth.currentUser.uid;
      this.props.firebase.user(userID).get()
        .then(async user => {
          const currentUser = user.data();
          this._isMounted && this.setState({
            userID: userID,
            businessName: currentUser.businessName,
            businessAddress: currentUser.businessAddress,
            phoneNumber: currentUser.phoneNumber,
            emailAddress: this.props.firebase.auth.currentUser.email,
            originalAvatarBase64: await urlToBase64(currentUser.originalAvatarSrc),
            originalAvatarSrc: currentUser.originalAvatarSrc,
            previewAvatarSrc: currentUser.previewAvatarSrc
          })
        });
    }
  }

  handleModalClose = () => {
    this.setState({ open: false })
  }

  onClose = () => {
    this.setState({ previewAvatarBase64: null })
  }

  onCrop = (previewAvatarBase64) => {
    console.log(`preview===`, previewAvatarBase64)
    this.setState({ previewAvatarBase64 })
  }

  onBeforeFileLoad = (elem) => {
    if (elem.target.files[0].size > 71680) {
      alert("File is too big!");
      elem.target.value = "";
    };
  }

  handleFileUpload = (file) => {
    let avatarRef = this.props.firebase.storage.ref();
    const userID = this.props.firebase.auth.currentUser.uid;
    let avatarUserRef = avatarRef.child(`${userID}/originalAvatar.jpg`);
    const __this = this;
    avatarUserRef.put(file)
      .then(function (snapshot) {
        snapshot.ref.getDownloadURL()
          .then(function (downloadUrl) {
            __this.setState({ originalAvatarSrc: downloadUrl })
          })
      })
      .catch(error => console.log(`error===`, error))
  }

  handleSaveAvatar = () => {
    const img_b64 = this.state.previewAvatarBase64;
    const png = img_b64.split(',')[1];
    const previewAvatarFile = b64toBlob(png, 'image/png')
    let avatarRef = this.props.firebase.storage.ref();
    const userID = this.props.firebase.auth.currentUser.uid;
    let previewAvatarUserRef = avatarRef.child(`${userID}/previewAvatar.jpg`);
    const __this = this;
    previewAvatarUserRef.put(previewAvatarFile)
      .then(function (snapshot) {
        snapshot.ref.getDownloadURL()
          .then(function (downloadUrl) {
            __this.setState({ previewAvatarSrc: downloadUrl, open: false })
          })
      })
      .catch(error => console.log(`error===`, error))
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  reauthenticate = (currentPassword) => {
    var user = this.props.firebase.auth.currentUser;
    var cred = this.props.firebase.emailAuthProvider.credential(
      user.email, currentPassword);
    return user.reauthenticateAndRetrieveDataWithCredential(cred);
  }

  handleSubmit = () => {
    const {
      businessName,
      businessAddress,
      phoneNumber,
      emailAddress,
      originalAvatarSrc,
      previewAvatarSrc
    } = this.state;
    const userID = this.props.firebase.auth.currentUser.uid;

    if (emailAddress !== this.props.firebase.auth.currentUser.email) {
      this.changeEmail();
    } else {
      this.props.firebase.user(userID).update({
        businessName,
        businessAddress,
        phoneNumber,
        emailAddress,
        originalAvatarSrc,
        previewAvatarSrc
      })
        .then(user => {
          this.changePassword();
        })
    }
  }

  changePassword = () => {
    const {
      currentPassword,
      newPassword,
      confirmPassword
    } = this.state;
    if (currentPassword !== '' && newPassword !== '' && confirmPassword !== '') {
      let errors = {};

      this.reauthenticate(currentPassword).then(() => {
        if (newPassword === confirmPassword) {
          this.props.firebase.doPasswordUpdate(newPassword).then(() => {
            this.props.firebase.doSignOut();
          }).catch((error) => { console.log(error); });
        } else {
          errors.confirmPassword = "Your new passwords do not match one another. Please check this and try again."
          this.setState({ errors });
        }
      }).catch((error) => {
        errors.currentPassword = "Your existing password was incorrect."
        this.setState({ errors });
      });
    } else {
      this.props.history.push(`page:deals`);
    }
  }

  changeEmail = () => {
    const {
      businessName,
      businessAddress,
      phoneNumber,
      currentPassword,
      emailAddress
    } = this.state;
    const userID = this.props.firebase.auth.currentUser.uid;
    let errors = {};
    const __this = this;
    this.reauthenticate(currentPassword).then(() => {
      const user = this.props.firebase.auth.currentUser;
      user.updateEmail(emailAddress).then(() => {
        // update user data
        __this.props.firebase.user(userID).update({
          businessName,
          businessAddress,
          phoneNumber,
          emailAddress,
        })
          .then(user => {
            __this.changePassword();
            __this.props.firebase.doSignOut()
          }).catch((error) => { console.log(error); })
      }).catch((error) => { console.log(error); });
    }).catch((error) => {
      errors.currentPassword = "To change the email address on your account please enter your current password";
      __this.setState({ errors });
    });
  }

  render() {

    const { classes } = this.props;
    const { errors, open, previewAvatarSrc, originalAvatarSrc, originalAvatarBase64 } = this.state;

    return (
      <div>
        <Card className={classes.card}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={12}
              lg={4}
              style={{
                minWidth: 200,
                paddingTop: 50,
                display: "flex",
                justifyContent: "center"
              }}
            >
              <div
                style={{
                  width: 200,
                  height: 200,
                  borderRadius: "50%",
                  backgroundColor: "#ECECEC",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center"
                }}
                onClick={() => this.setState({ open: true })}
              >
                {!previewAvatarSrc && (
                  <div style={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column"
                  }}>
                    <AddPhotoAlternateOutlinedIcon style={{ width: 70, height: 70 }} />
                    <Typography style={{
                      fontWeight: "600",
                      fontSize: 12,
                      color: "#797979",
                      marginTop: 10
                    }}>
                      Add business logo
                    </Typography>
                  </div>
                )}
                {previewAvatarSrc && (
                  <img
                    src={previewAvatarSrc}
                    alt="Preview"
                    style={{
                      width: "100%",
                      height: "100%"
                    }}
                  />
                )}
              </div>
            </Grid>
            <Grid item xs={12} lg={8}>
              <Typography className={classes.infoTitle}>
                Business Information
              </Typography>
              {businessInfoItems.map((item, index) => {
                return (
                  <div key={index} style={{ marginTop: 12, marginLeft: 20 }}>
                    <Typography className={classes.detailInfo}>
                      {item.name}
                    </Typography>
                    <Paper className={classes.itemInput}>
                      <InputBase
                        className={classes.input}
                        placeholder={item.name}
                        name={item.value}
                        type={item.type}
                        value={this.state[item.value]}
                        inputProps={{ 'aria-label': `${item.value}` }}
                        onChange={this.handleInputChange}
                      />
                    </Paper>
                  </div>
                )
              })}
            </Grid>
          </Grid>
          <Grid container spacing={2} style={{ marginTop: 30 }}>
            <Grid item xs={12} lg={8}>
              <Typography className={classes.infoTitle}>
                Account Information
            </Typography>
              <div style={{ marginTop: 12, marginLeft: 20 }}>
                <Typography className={classes.detailInfo}>
                  Email Address
              </Typography>
                <Paper className={classes.itemInput}>
                  <InputBase
                    className={classes.input}
                    placeholder='Email Address'
                    name={'emailAddress'}
                    type={'email'}
                    value={this.state.emailAddress}
                    inputProps={{ 'aria-label': `Email Address` }}
                    onChange={this.handleInputChange}
                  />
                </Paper>
              </div>
              <Typography className={classes.changePwd}>
                Change Password
              </Typography>
              {changePasswordItems.map((item, index) => {
                return (
                  <div key={index} style={{ marginTop: 12, marginLeft: 20 }}>
                    <Typography className={classes.detailInfo}>
                      {item.name}
                    </Typography>
                    <Paper className={classes.itemInput}>
                      <InputBase
                        className={classes.input}
                        placeholder={item.name}
                        name={item.value}
                        type={item.type}
                        value={this.state[item.value]}
                        inputProps={{ 'aria-label': `${item.value}` }}
                        onChange={this.handleInputChange}
                      />
                    </Paper>
                    {errors && errors[item.value] &&
                      <Typography
                        color="error"
                        className={classes.errorText}
                      >
                        {errors[item.value]}
                      </Typography>
                    }
                  </div>
                )
              })}
              <div style={{ display: "flex", justifyContent: "flex-end", marginTop: 32 }}>
                <Button
                  variant="contained"
                  color={"primary"}
                  style={{ backgroundColor: "#4c5df8" }}
                  className={classes.editDealButton}
                  onClick={this.handleSubmit}
                >
                  Save Updates
                </Button>
              </div>
            </Grid>
            <Grid item xs={12} lg={4} style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-end",
              paddingBottom: 20
            }}>
              <img src={LogoImage} alt="logo" />
              <img src={LogoTitle} alt="logo" />
            </Grid>
          </Grid>
        </Card>
        <Dialog
          fullScreen={false}
          open={open}
          onClose={this.handleModalClose}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogContent style={{ margin: 20 }}>
            {originalAvatarSrc ? (
              <Avatar
                width={390}
                height={295}
                onCrop={this.onCrop}
                onClose={this.onClose}
                onBeforeFileLoad={this.onBeforeFileLoad}
                src={`data:image/png;base64, ${originalAvatarBase64}`}
                onFileLoad={this.handleFileUpload}
              />
            ) : (
              <Avatar
                width={390}
                height={295}
                onCrop={this.onCrop}
                onClose={this.onClose}
                onBeforeFileLoad={this.onBeforeFileLoad}
                src={""}
                onFileLoad={this.handleFileUpload}
              />
            )
            }
          </DialogContent>
          <DialogActions style={{ margin: 20 }}>
            <Button onClick={this.handleModalClose} color="primary">
              Cancel
            </Button>
            <Button onClick={this.handleSaveAvatar} color="primary" autoFocus variant={"contained"}>
              Save
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withStyles(styles)
)(Account);