import React from 'react';
import { compose } from 'recompose';
import axios from 'axios';
import {
  Card,
  Paper,
  InputBase,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Slide
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization, withEmailVerification } from '../Session';
import { contactInfoItems } from '../../constants/constants';
import { styles } from "../../assets/styles/faqPageStyle";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

class FAQPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      yourName: '',
      yourEmailAddress: '',
      yourPhoneNumber: '',
      message: '',
      openDialog: false
    }
  }

  handleInputChange = e => {
    this.setState({
      [e.target.name]: e.target.value
    })
  }

  handleClose = () => {
    this.setState({
      openDialog: false
    })
  }

  handleSubmit = () => {
    const {
      yourName,
      yourEmailAddress,
      yourPhoneNumber,
      message
    } = this.state;
    console.log(`=====`, yourName, yourEmailAddress, yourPhoneNumber, message);
    axios.get(`https://us-central1-greenbelt-f4e8c.cloudfunctions.net/sendMail?name=${yourName}&email=${yourEmailAddress}&message=${message}`)
      .then(res =>
        this.setState({ openDialog: true })
      )
      .catch(err => console.log(`err===`, err))
  }

  render() {

    const { classes } = this.props;
    const { message, openDialog } = this.state;

    return (
      <div style={{ height: "100%" }}>
        <Card className={classes.cardContain}>
          {contactInfoItems.map((item, index) => {
            return (
              <div key={index} style={{ marginTop: 20 }}>
                <Paper className={classes.itemInput}>
                  <InputBase
                    className={classes.input}
                    placeholder={item.name}
                    name={item.value}
                    type={item.type}
                    value={this.state[item.value]}
                    inputProps={{ 'aria-label': `${item.value}` }}
                    onChange={this.handleInputChange}
                  />
                </Paper>
              </div>
            )
          })}
          <Paper className={classes.descriptionContainer}>
            <InputBase
              className={classes.description}
              placeholder='Type your message here... '
              inputProps={{ 'aria-label': `deal-description` }}
              multiline={true}
              name={'message'}
              value={message}
              onChange={this.handleInputChange}
            />
          </Paper>
          <div style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            height: "15%"
          }}>
            <Button
              variant="contained"
              color={"primary"}
              style={{ backgroundColor: "#4c5df8" }}
              className={classes.editDealButton}
              onClick={this.handleSubmit}
            >
              Send Email
            </Button>
          </div>
        </Card>
        <Dialog
          open={openDialog}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Message sent!
          </DialogTitle>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary" variant="contained">
              OK
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withStyles(styles)
)(FAQPage);
