export const styles = theme => ({
  container: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#F2F4FD",
    paddingTop: theme.spacing(5),
    paddingLeft: theme.spacing(10),
    paddingRight: "15%",
    overflow: "auto"
  },
  sideBar: {
    width: "25%",
    minWidth: 205,
    height: "100%"
  },
  title: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: 28,
    color: "#0D0D0D"
  },
  listItemTextRoot: {
    padding: 0
  },
  listItemTextLabel: {
    color: "#646464",
    fontSize: 20,
    fontWeight: "600"
  },
  header: {
    display: "flex"
  },
  containerTitle: {
    fontFamily: "Nunito Sans",
    fontWeight: "600",
    fontSize: 24,
    color: "#0D0D0D",
    marginTop: 20
  },
  createNewDealsButton: {
    width: 211,
    height: 44,
    marginBottom: 15,
    float: 'right',
    background: '#4D87F8',
    textTransform: "none"
  }
});