import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Container, 
  Typography
} from "@material-ui/core";
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import { withStyles } from '@material-ui/core/styles';
import { styles } from '../../assets/styles/authStyle';

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { passwordOne } = this.state;

    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        this.setState({ ...INITIAL_STATE });
      })
      .catch(error => {
        this.setState({ error: error.message });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { 
      passwordOne, 
      passwordTwo, 
      error 
    } = this.state;

    const { 
      classes 
    } = this.props;

    const isInvalid =
      passwordOne !== passwordTwo || passwordOne === '';

    return (
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <div className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Change Password
          </Typography>
          <form className={classes.form} noValidate onSubmit={this.onSubmit}>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              id="email"
              label="New Password"
              type="password"
              name="passwordOne"
              autoComplete="off"
              autoFocus
              onChange={this.onChange}
            />
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              name="passwordTwo"
              label="Confirm New Password"
              type="password"
              id="password"
              autoComplete="off"
              onChange={this.onChange}
            />
            { error && (
              <Typography
                color="error"
                className={classes.errorText}
              >
                {error}
              </Typography>
            )}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              color="primary"
              className={classes.submit}
              disabled={isInvalid}
            >
              Reset My Password
            </Button>
          </form>
        </div>
      </Container>
    );
  }
}

const PasswordChangeForm = compose(
  withRouter,
  withFirebase,
  withStyles(styles)
)(PasswordChangeFormBase);

export default PasswordChangeForm;
