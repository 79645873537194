export const LANDING = '/';
export const SIGN_UP = '/signup';
export const SIGN_IN = '/signin';
export const CONFIRM_EMAIL = '/confirmemail';
export const ADS = 'ads';
export const ACCOUNT = 'account';
export const PASSWORD_FORGET = '/pw-forget';
export const PASSWORD_CHANGE = '/pw-change';
export const EDITDEALS = 'edit';
export const CREATEDEALS = 'create';
export const FAQ = 'help';
