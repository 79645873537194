import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/database';
import 'firebase/firestore';
import 'firebase/storage';
import firebaseConfig from './config';

const config = {
  apiKey: firebaseConfig.apiKey,
  authDomain: firebaseConfig.authDomain,
  databaseURL: firebaseConfig.databaseURL,
  projectId: firebaseConfig.projectId,
  storageBucket: firebaseConfig.storageBucket,
  messagingSenderId: firebaseConfig.messagingSenderId,
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.serverValue = app.database.ServerValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth = app.auth();
    this.db = app.firestore();
    this.storage = app.storage();
  }

  // *** Auth API ***

  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);

  doSignOut = () => this.auth.signOut();

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () => {
  console.log(`called`)
    this.auth.currentUser.sendEmailVerification({
      url: firebaseConfig.confirmationEmailRedirect,
    })};

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid).get()
          .then(documentSnapshot => {
            if (documentSnapshot.exists) {
              const dbUser = documentSnapshot.data();
              // default empty roles
              if (!dbUser.roles) {
                dbUser.roles = {};
              }

              // merge auth and db user
              authUser = {
                uid: authUser.uid,
                emailAddress: authUser.email,
                emailVerified: authUser.emailVerified,
                providerData: authUser.providerData,
                ...dbUser,
              };

              next(authUser);
            }
          });
      } else {
        fallback();
      }
    });

  getDeals = async () => {
    const authID = this.auth.currentUser && this.auth.currentUser.uid;
    const snapshot = await this.deals().orderBy('isAlive', 'desc').get()
    let deals = [];
    snapshot.docs
      .filter(doc => authID && doc.data().createdBy === authID)
      .map(doc => authID && deals.push(doc.data()));
    return deals;
  }

  // *** User API ***

  user = uid => this.db.collection(`users`).doc(`${uid}`);

  users = () => this.db.collection('users');

  // *** Deals API ***

  deal = uid => this.db.collection(`deals`).doc(`${uid}`);

  deals = () => this.db.collection(`deals`);
}

export default Firebase;
