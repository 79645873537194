// ======  Prod server configuration
const firebaseConfig = {
  apiKey: "AIzaSyDoNdg1UUA_L669Xbk6cECYiLFabI5kbAc",
  authDomain: "greenbelt-f4e8c.firebaseapp.com",
  databaseURL: "https://greenbelt-f4e8c.firebaseio.com",
  projectId: "greenbelt-f4e8c",
  storageBucket: "greenbelt-f4e8c.appspot.com",
  messagingSenderId: "589065904132",
  appId: "1:589065904132:web:0ea40da15222322f",
  confirmationEmailRedirect: "https://portal.boisegreenbelt.app/page:account",
  // confirmationEmailRedirect: "https//localhost:3000/page:account"
}

// ======  Staging server configuration

// const firebaseConfig = {
//   apiKey: "AIzaSyDX1jmUSykqZiJZQGBzYSzQnAEzoafa77M",
//   authDomain: "staging-greenbelt.firebaseapp.com",
//   databaseURL: "https://staging-greenbelt.firebaseio.com",
//   projectId: "staging-greenbelt",
//   storageBucket: "staging-greenbelt.appspot.com",
//   messagingSenderId: "930180935271",
//   appId: "1:930180935271:web:011abc4e46ad70b23ceec2",
//   confirmationEmailRedirect: "https://staging-greenbelt.firebaseapp.com/page:account",
//   // confirmationEmailRedirect: "http://localhost:3000/page:account"
// };


export default firebaseConfig;