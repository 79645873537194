import React, {Component} from 'react';
import Dropzone from 'react-dropzone';
import _ from "../../@lodash";
import { withStyles } from "@material-ui/core/styles";
import AddPhotoAlternateOutlinedIcon from "@material-ui/icons/AddPhotoAlternateOutlined";

const styles = theme => ({
  container: {
    height: "100%",
    width: "65%",
    margin: 15
  }
});

class DropzonePlugin extends Component {

  onDrop = (uploadedFiles) => {
    const oldFileList = this.props.files;
    oldFileList.push(uploadedFiles);
    Object.defineProperty(Array.prototype, 'flat', {
      value: function(depth = 1) {
        return this.reduce(function (flat, toFlatten) {
          return flat.concat((Array.isArray(toFlatten) && (depth>1)) ? toFlatten.flat(depth-1) : toFlatten);
        }, []);
      }
    });
    const files = _.uniqBy(oldFileList.flat(), function(e) {
      return JSON.stringify(e);
    })
    this.props.handleFileUpload(files)
  };

  render() {
    let files;
    if (this.props.files.length >= 1) {
      files = this.props.files.map((file,i) => (
        <li key={i}>
          {file.name}
        </li>
      ));
    } else {
      files = []
    }    

    const { classes } = this.props;

    return (
      <Dropzone onDrop={this.onDrop}>
        {({getRootProps, getInputProps}) => (
          <section className={classes.container}>
            <div {...getRootProps({className: 'dropzone'})} style={{
              height: "100%",
              backgroundColor: "#EEEEEE",
              borderRadius: 8
            }}>
              <input {...getInputProps()} />
              <div style={{
                display: "flex", 
                justifyContent: "center", 
                height: "100%", 
                flexDirection: "column", 
                alignItems: "center"
              }}>
                <AddPhotoAlternateOutlinedIcon style={{width: 120, height: 120}}/>
              </div>
            </div>
            {
              files.length >= 1 && (
                <aside style={{marginTop: -200, marginLeft: 30}}>
                  <h4>Files</h4>
                  <ul>{files}</ul>
                </aside>
              )
            }            
          </section>
        )}
      </Dropzone>
    );
  }
}

export default withStyles(styles)(DropzonePlugin);