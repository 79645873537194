export const styles = theme => ({
  '@global': {
    body: {
      backgroundColor: theme.palette.common.white,
    },
  },
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  errorText: {
    fontSize: 12,
    fontWeight: "500",
    textAlign: "center"
  },
  container: {
    width: "100%",
    height: "100vh",
    padding: theme.spacing(8),
    backgroundColor: "#F2F4FD",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "column"
  },
  titleContain: {
    width: "50%",
    display: "flex",
    justifyContent: "flex-start"
  },
  containCard: {
    width: "50%",
    height: "50%",
    padding: theme.spacing(5),
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center"
  },
  textWrap: {
    width: "65%",
    height: "60%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
  },
  detailText: {
    fontFamily: "Nuito Sans",
    fontSize: 16,
    textAlign: "center",
    color: "#0D0D0D"
  },
  buttonWrap: {
    display: "flex",
    justifyContent: "center",
    width: "65%",
  },
  messageWrap: {
    width: "65%",
    height: "5%"
  }
});