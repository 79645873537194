import React from 'react';
import { Link } from "react-router-dom";
import { compose } from 'recompose';
import {
  Grid,
  Button,
  Typography,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Menu,
  MenuItem
} from "@material-ui/core";
import MoreOutlinedIcon from "@material-ui/icons/MoreOutlined";
import AccountCircleOutlinedIcon from "@material-ui/icons/AccountCircleOutlined";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import InputIcon from "@material-ui/icons/Input";
import { withStyles } from "@material-ui/core/styles";
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROUTES from '../../constants/routes';
import Ads from '../Ads';
import Account from "../Account";
import FAQ from "../FAQ";
import { styles } from "../../assets/styles/homePageStyle";

const AdapterLink = React.forwardRef((props, ref) => <Link innerRef={ref} {...props} />);

class HomePage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      businessName: '',
      anchorEl: null
    }
  }

  componentDidMount() {
    if (this.props.firebase.auth.currentUser) {
      const userID = this.props.firebase.auth.currentUser.uid;
      this.props.firebase.user(userID).get()
        .then(user => {
          const currentUser = user.data();
          this.setState({
            businessName: currentUser.businessName
          })
        });
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.firebase.auth.currentUser) {
      const userID = this.props.firebase.auth.currentUser.uid;
      this.props.firebase.user(userID).get()
        .then(user => {
          const currentUser = user.data();
          this.setState({
            businessName: currentUser.businessName
          })
        });
    }
  }

  handleClick = (e) => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleGoFAQ = () => {
    this.setState({ anchorEl: null })
  }

  handleLogOut = () => {
    this.setState({ anchorEl: null })
    this.props.firebase.doSignOut();
  }

  render() {

    const { classes } = this.props;
    const { componentName } = this.props.match.params;
    const { businessName, anchorEl } = this.state;
    const openMenu = Boolean(anchorEl);

    return (
      <Grid container className={classes.container}>
        <Grid item xs={12} sm={3}>
          <div className={classes.sideBar}>
            <Typography className={classes.title}>
              {businessName}
            </Typography>
            <div style={{ marginTop: 30 }}>
              <Link to={`/page:${ROUTES.ADS}`}>
                <ListItem button>
                  <ListItemIcon>
                    <MoreOutlinedIcon style={{ color: "#646464" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Ads"
                    classes={{
                      root: classes.listItemTextRoot,
                      primary: classes.listItemTextLabel
                    }} />
                </ListItem>
              </Link>
              <Link to={`/page:${ROUTES.ACCOUNT}`}>
                <ListItem button>
                  <ListItemIcon>
                    <AccountCircleOutlinedIcon style={{ color: "#646464" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary="Account"
                    classes={{
                      root: classes.listItemTextRoot,
                      primary: classes.listItemTextLabel
                    }} />
                </ListItem>
              </Link>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={9}>
          <Grid container className={classes.header}>
            <Grid item xs={4}>
              <Typography className={classes.containerTitle}>
                {componentName === ':ads' && "Ads"}
                {componentName === ':account' && "Account"}
                {componentName === ':help' && "Contact Us"}
              </Typography>
            </Grid>
            <Grid item xs={8} style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center"
            }}>
              <Button
                variant="contained"
                component={AdapterLink}
                to={`/ads-${ROUTES.CREATEDEALS}`}
                color="primary"
                className={classes.createNewDealsButton}>
                Create New Ad
              </Button>
              <IconButton
                aria-label="more"
                aria-controls="long-menu"
                aria-haspopup="true"
                onClick={this.handleClick}
                style={{ marginBottom: 11 }}
              >
                <ArrowDropDownIcon />
              </IconButton>
              <Menu
                id="long-menu"
                anchorEl={anchorEl}
                keepMounted
                open={openMenu}
                onClose={this.handleClose}
                style={{ padding: 0 }}
                PaperProps={{
                  style: {
                    height: 96,
                    width: 150,
                    marginTop: 45,
                  },
                }}
              >
                <MenuItem component={AdapterLink} to={`page:${ROUTES.FAQ}`} onClick={this.handleGoFAQ}>
                  <ListItemIcon className="min-w-40">
                    <HelpOutlineOutlinedIcon />
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="Help" />
                </MenuItem>
                <MenuItem onClick={this.handleLogOut}>
                  <ListItemIcon className="min-w-40">
                    <InputIcon />
                  </ListItemIcon>
                  <ListItemText className="pl-0" primary="Log Out" />
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
          {componentName === ':ads' && <Ads />}
          {componentName === ':account' && <Account />}
          {componentName === ':help' && <FAQ />}
        </Grid>
      </Grid>
    )
  }
}

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  withStyles(styles)
)(HomePage);
