import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {
  Card,
  Button,
  Typography
} from "@material-ui/core";
import { withStyles } from '@material-ui/core/styles';
import { withAuthentication } from "../Session";
import LogoImage from "../../assets/images/logo.svg";
import LogoTitle from "../../assets/images/logoTitle.svg";
import { styles } from '../../assets/styles/authStyle';

import { withFirebase } from '../Firebase';

class ConfirmEmailBase extends Component {

  constructor(props) {
    super(props);
    this.state = {
      message: ''
    }
  }

  confirmEmail = event => {
    event.preventDefault();
    this.props.firebase.doSendEmailVerification();
    this.setState({
      message: "Verification email was sent!"
    })
  };

  render() {
    const { classes } = this.props;
    const { message } = this.state;

    return (
      <div className={classes.container}>
        <div className={classes.titleContain}>
          <Typography style={{
            fontFamily: "Nunito Sans",
            fontWeight: "600",
            fontSize: 24,
            color: "#0D0D0D"
          }}>
            Verify your email
          </Typography>
        </div>
        <Card className={classes.containCard}>
          <div className={classes.textWrap}>
            <Typography style={{
              fontFamily: "Nunito Sans",
              fontWeight: "600",
              fontSize: 22,
              color: "#0D0D0D"
            }}>
              Verify your email address
            </Typography>
            <Typography className={classes.detailText}>
              Check your email (including your spam folder) for a confirmation e-mail.
            </Typography>
            <Typography className={classes.detailText}>
              If you did not receive the confirmation email, you can send another one with the button below.
            </Typography>
          </div>
          <div className={classes.buttonWrap}>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#4c5df8",
                height: 40,
                borderRadius: 40,
                textTransform: "capitalize"
              }}
              onClick={this.confirmEmail}
            >
              Send Confirmation Email
            </Button>
          </div>
          <div className={classes.messageWrap}>
          { message && (
            <Typography
              color="error"
              className={classes.errorText}
            >
              {message}
            </Typography>
          )}
          </div>
        </Card>
        <div style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          marginTop: "-10%",
          marginLeft: "35%"
        }}>
          <img src={LogoImage} alt="logo" />
          <img src={LogoTitle} alt="logo" />
        </div>
      </div>
    );
  }
}

const ConfirmEmailPage = compose(
  withRouter,
  withFirebase,
  withAuthentication,
  withStyles(styles)
)(ConfirmEmailBase);

export default ConfirmEmailPage;